* {
  box-sizing: border-box;
}

body, html {
  width:    100%;
  height:   100%;
  margin:   0;
  padding:  0;
  position: relative;
  overflow: hidden;
}

body, html {
  background-color: #fff;
}

.main-container {
  margin:   0;
  padding:  0;
  width:    100%;
  height:   100%;
  position: relative;
  overflow: hidden;
}

.extra-info {
  padding:       10px;
  margin:        5px;
  border:        1px solid #000;
  border-radius: 10px;
  display:       inline-block;
  font-family:   'Lato', sans-serif;
  font-size:     14px;
  color: #000;
}

.active-types-container {
  margin:        10px;
  padding:       20px;
  border:        5px solid limegreen;
  border-radius: 80px;
  width:         290px;
  height:        170px;

  &.inverse {
    border-color: darkred;
  }

  .active-type {
    display: inline-block;
    margin:  10px;
  }
}

.all-types-container {
  margin:        10px;
  padding:       20px;
  border:        5px solid dodgerblue;
  border-radius: 80px;
  width:         1130px;
  min-height:    290px;

  .type {
    display: inline-block;
    margin:  10px;
  }
}

.icon {
  border-radius: 100%;
  height:        100px;
  width:         100px;
  margin:        auto;
  transition:    filter 200ms, transform 200ms;
  border:        5px solid #fff;
  cursor:        pointer;

  &.x0 {
    border-color: #000;
  }

  &.x0_25 {
    border-color: red;
  }

  &.x0_5 {
    border-color: orange;
  }

  &.x1 {
    border-color: #fff;
  }

  &.x2 {
    border-color: dodgerblue;
  }

  &.x4 {
    border-color: #0f0;
  }

  &:hover {
    filter:    saturate(200%);
    transform: scale(1.1);
  }

  span {
    height:  60%;
    width:   60%;
    margin:  20%;
    display: block;
  }
}

.icon-bug {
  background-image: url('/images/type-icons/bug.svg');
}

.icon-dark {
  background-image: url('/images/type-icons/dark.svg');
}

.icon-dragon {
  background-image: url('/images/type-icons/dragon.svg');
}

.icon-electric {
  background-image: url('/images/type-icons/electric.svg');
}

.icon-fire {
  background-image: url('/images/type-icons/fire.svg');
}

.icon-fairy {
  background-image: url('/images/type-icons/fairy.svg');
}

.icon-fighting {
  background-image: url('/images/type-icons/fighting.svg');
}

.icon-flying {
  background-image: url('/images/type-icons/flying.svg');
}

.icon-ghost {
  background-image: url('/images/type-icons/ghost.svg');
}

.icon-grass {
  background-image: url('/images/type-icons/grass.svg');
}

.icon-ground {
  background-image: url('/images/type-icons/ground.svg');
}

.icon-ice {
  background-image: url('/images/type-icons/ice.svg');
}

.icon-normal {
  background-image: url('/images/type-icons/normal.svg');
}

.icon-poison {
  background-image: url('/images/type-icons/poison.svg');
}

.icon-psychic {
  background-image: url('/images/type-icons/psychic.svg');
}

.icon-rock {
  background-image: url('/images/type-icons/rock.svg');
}

.icon-steel {
  background-image: url('/images/type-icons/steel.svg');
}

.icon-water {
  background-image: url('/images/type-icons/water.svg');
}

.icon-element {
  background-size: 100% 100%;
}

.bug {
  background: #92bc2c;
  box-shadow: 0 0 20px #92bc2c;
}

.dark {
  background: #595761;
  box-shadow: 0 0 20px #595761;
}

.dragon {
  background: #0c69c8;
  box-shadow: 0 0 20px #0c69c8;
}

.electric {
  background: #f2d94e;
  box-shadow: 0 0 20px #f2d94e;
}

.fire {
  background: #fba54c;
  box-shadow: 0 0 20px #fba54c;
}

.fairy {
  background: #ee90e6;
  box-shadow: 0 0 20px #ee90e6;
}

.fighting {
  background: #d3425f;
  box-shadow: 0 0 20px #d3425f;
}

.flying {
  background: #a1bbec;
  box-shadow: 0 0 20px #a1bbec;
}

.ghost {
  background: #5f6dbc;
  box-shadow: 0 0 20px #5f6dbc;
}

.grass {
  background: #5fbd58;
  box-shadow: 0 0 20px #5fbd58;
}

.ground {
  background: #da7c4d;
  box-shadow: 0 0 20px #da7c4d;
}

.ice {
  background: #75d0c1;
  box-shadow: 0 0 20px #75d0c1;
}

.normal {
  background: #a0a29f;
  box-shadow: 0 0 20px #a0a29f;
}

.poison {
  background: #b763cf;
  box-shadow: 0 0 20px #b763cf;
}

.psychic {
  background: #fa8581;
  box-shadow: 0 0 20px #fa8581;
}

.rock {
  background: #c9bb8a;
  box-shadow: 0 0 20px #c9bb8a;
}

.steel {
  background: #5695a3;
  box-shadow: 0 0 20px #5695a3;
}

.water {
  background: #539ddf;
  box-shadow: 0 0 20px #539ddf;
}

// 100px - 9x2 (~2.3; ~23/10)
@media screen and (min-width: 1150px) and (min-height: 500px) {
  .all-types-container {
    width:      1130px;
    min-height: 290px;
  }
  .active-types-container {
    width:  290px;
    height: 170px;

    &.inverse {
      width: 170px;
    }
  }
  .active-types-container, .all-types-container {
    border-radius: 85px;
    padding:       20px;
    margin-left:   10px;
    margin-right:  10px;
  }
  .icon {
    width:  100px;
    height: 100px;
  }
}

// 100px - 6x3 (~1.275; ~51/40)
@media screen and (min-width: 790px) and (min-height: 620px) {
  @media (max-width: 1150px - 1px) {
    .all-types-container {
      width:      770px;
      min-height: 290px;
    }
    .active-types-container {
      width:  290px;
      height: 170px;

      &.inverse {
        width: 170px;
      }
    }
    .active-types-container, .all-types-container {
      border-radius: 85px;
      padding:       20px;
      margin-left:   10px;
      margin-right:  10px;
    }
    .icon {
      width:  100px;
      height: 100px;
    }
  }
}

// 100px - 3x6 (~0.4388; ~7/16)
@media screen and (min-width: 430px) and (min-height: 980px) {
  @media (max-width: 790px - 1px) {
    .all-types-container {
      width:      410px;
      min-height: 290px;
    }
    .active-types-container {
      width:  290px;
      height: 170px;

      &.inverse {
        width: 170px;
      }
    }
    .active-types-container, .all-types-container {
      border-radius: 85px;
      padding:       20px;
      margin-left:   auto;
      margin-right:  auto;
    }
    .icon {
      width:  100px;
      height: 100px;
    }
  }
}

// 80px - 9x2
@media screen and (min-width: 970px) and (min-height: 440px) {
  @media (max-width: 1150px - 1px) and (max-height: 620px - 1px) {
    .all-types-container {
      width:      950px;
      min-height: 250px;
    }
    .active-types-container {
      width:  250px;
      height: 150px;

      &.inverse {
        width: 150px;
      }
    }
    .active-types-container, .all-types-container {
      border-radius: 75px;
      padding:       20px;
      margin-left:   10px;
      margin-right:  10px;
    }
    .icon {
      width:  80px;
      height: 80px;
    }
  }
}

// 80px - 6x3
@media screen and (min-width: 670px) and (min-height: 540px) {
  @media (max-width: 790px - 1px) and (max-height: 980px - 1px) {
    .all-types-container {
      width:      650px;
      min-height: 250px;
    }
    .active-types-container {
      width:  250px;
      height: 150px;

      &.inverse {
        width: 150px;
      }
    }
    .active-types-container, .all-types-container {
      border-radius: 75px;
      padding:       20px;
      margin-left:   10px;
      margin-right:  10px;
    }
    .icon {
      width:  80px;
      height: 80px;
    }
  }
}

// 80px - 3x6
@media screen and (min-width: 370px) and (min-height: 840px) {
  @media (max-width: 670px - 1px) {
    .all-types-container {
      width:      350px;
      min-height: 250px;
    }
    .active-types-container {
      width:  250px;
      height: 150px;

      &.inverse {
        width: 150px;
      }
    }
    .active-types-container, .all-types-container {
      border-radius: 75px;
      padding:       20px;
      margin-left:   auto;
      margin-right:  auto;
    }
    .icon {
      width:  80px;
      height: 80px;
    }
  }
}

// 60px - 9x2
@media screen and (min-width: 790px) and (min-height: 380px) {
  @media (max-height: 500px - 1px), (max-height: 540px - 1px) and (max-width: 970px - 1px) {
    .all-types-container {
      width:      770px;
      min-height: 210px;
    }
    .active-types-container {
      width:  210px;
      height: 130px;

      &.inverse {
        width: 130px;
      }
    }
    .active-types-container, .all-types-container {
      border-radius: 65px;
      padding:       20px;
      margin-left:   10px;
      margin-right:  10px;
    }
    .icon {
      width:  60px;
      height: 60px;
    }
  }
}

// 60px - 6x3
@media screen and (min-width: 550px) and (min-height: 460px) {
  @media (max-width: 790px - 1px) and (max-height: 540px - 1px), (max-width: 670px - 1px) {
    .all-types-container {
      width:      530px;
      min-height: 210px;
    }
    .active-types-container {
      width:  210px;
      height: 130px;

      &.inverse {
        width: 130px;
      }
    }
    .active-types-container, .all-types-container {
      border-radius: 65px;
      padding:       20px;
      margin-left:   10px;
      margin-right:  10px;
    }
    .icon {
      width:  60px;
      height: 60px;
    }
  }
}

// 60px - 3x6
@media screen and (min-width: 310px) and (min-height: 700px) {
  @media (max-width: 550px - 1px) and (max-height: 840px - 1px), (max-width: 370px - 1px) {
    .all-types-container {
      width:      290px;
      min-height: 210px;
    }
    .active-types-container {
      width:  210px;
      height: 130px;

      &.inverse {
        width: 130px;
      }
    }
    .active-types-container, .all-types-container {
      border-radius: 65px;
      padding:       20px;
      margin-left:   auto;
      margin-right:  auto;
    }
    .icon {
      width:  60px;
      height: 60px;
    }
  }
}

// 40px - 9x2
@media screen and (min-width: 590px) and (min-height: 280px) {
  @media (max-height: 380px - 1px), (max-width: 790px - 1px) and (max-height: 460px - 1px) {
    .all-types-container {
      width:      570px;
      min-height: 150px;
    }
    .active-types-container {
      width:  150px;
      height: 90px;

      &.inverse {
        width: 90px;
      }
    }
    .active-types-container, .all-types-container {
      border-radius: 45px;
      padding:       10px;
      margin-left:   10px;
      margin-right:  10px;
    }
    .icon {
      width:  40px;
      height: 40px;
    }
  }
}

// 40px - 6x3
@media screen and (min-width: 410px) and (min-height: 340px) {
  @media (max-width: 590px - 1px) and (max-height: 460px - 1px), (max-width: 550px - 1px) and (max-height: 700px - 1px) {
    .all-types-container {
      width:      390px;
      min-height: 150px;
    }
    .active-types-container {
      width:  150px;
      height: 90px;

      &.inverse {
        width: 90px;
      }
    }
    .active-types-container, .all-types-container {
      border-radius: 45px;
      padding:       10px;
      margin-left:   10px;
      margin-right:  10px;
    }
    .icon {
      width:  40px;
      height: 40px;
    }
  }
}

// 40px - 3x6
@media screen and (min-width: 230px) and (min-height: 520px) {
  @media (max-width: 410px - 1px) and (max-height: 700px - 1px), (max-width: 310px - 1px) {
    .all-types-container {
      width:      210px;
      min-height: 150px;
    }
    .active-types-container {
      width:  150px;
      height: 90px;

      &.inverse {
        width: 90px;
      }
    }
    .active-types-container, .all-types-container {
      border-radius: 45px;
      padding:       10px;
      margin-left:   auto;
      margin-right:  auto;
    }
    .icon {
      width:  40px;
      height: 40px;
    }
  }
}

// 36px - 9x2
@media screen and (min-width: 500px) and (min-height: 250px) {
  @media (max-height: 340px - 1px) and (max-width: 590px - 1px), (max-height: 280px - 1px) {
    .all-types-container {
      width:      480px;
      min-height: 130px;

      .type {
        margin: 7px;
      }
    }
    .active-types-container {
      width:  130px;
      height: 80px;

      &.inverse {
        width: 80px;
      }

      .active-type {
        margin: 7px;
      }
    }
    .active-types-container, .all-types-container {
      border-radius: 40px;
      padding:       10px;
      margin-left:   10px;
      margin-right:  10px;
    }
    .icon {
      width:  36px;
      height: 36px;
    }
  }
}

// 36px - 6x3
@media screen and (min-width: 350px) and (min-height: 300px) {
  @media (max-width: 410px - 1px) and (max-height: 520px - 1px) {
    .all-types-container {
      width:      330px;
      min-height: 130px;

      .type {
        margin: 7px;
      }
    }
    .active-types-container {
      width:  130px;
      height: 80px;

      &.inverse {
        width: 80px;
      }

      .active-type {
        margin: 7px;
      }
    }
    .active-types-container, .all-types-container {
      border-radius: 40px;
      padding:       10px;
      margin-left:   10px;
      margin-right:  10px;
    }
    .icon {
      width:  36px;
      height: 36px;
    }
  }
}

// 36px - 3x6
@media screen and (min-width: 200px) and (min-height: 450px) {
  @media (max-width: 350px - 1px) and (max-height: 520px - 1px) {
    .all-types-container {
      width:      180px;
      min-height: 130px;

      .type {
        margin: 7px;
      }
    }
    .active-types-container {
      width:  130px;
      height: 80px;

      &.inverse {
        width: 80px;
      }

      .active-type {
        margin: 7px;
      }
    }
    .active-types-container, .all-types-container {
      border-radius: 40px;
      padding:       10px;
      margin-left:   auto;
      margin-right:  auto;
    }
    .icon {
      width:  36px;
      height: 36px;
    }
  }
}


@media (prefers-color-scheme: dark) {
  body, html {
    background-color: #181A1F;
  }

  .extra-info {
    border-color: #fff;
    color: #fff;
  }
}

